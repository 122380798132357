<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t('common.material-forms.edit.title') }}</h3>
        <h3 v-else>{{ $t('common.material-forms.add.title') }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>

            <!-- Component -->
            <v-col cols="12" class="mt-12">
              <SelectComponent
                  :is-required="true"
                  :value="form.component"
                  @input="onInputComponent"
              />
            </v-col>

            <!-- Supplier -->
            <v-col cols="12" class="mt-3">
              <SelectSupplier
                  :is-required="true"
                :value="form.supplier"
                @input="onInputSupplier"
              />
            </v-col>

            <!-- Project impact -->
            <v-col cols="12" class="mt-3">
              <SelectProjectImpact
                  :is-required="true"
                :value="form.projectImpact"
                @input="onInputProjectImpact"
              />
            </v-col>

            <!-- Type -->
            <v-col cols="12" class="mt-3">
              <v-select
                  outlined
                  v-model="form.type"
                  :label="$t('common.material-forms.labels.type')"
                  :items="$t('constants.componentsTypes.list')"
              />
            </v-col>

            <!-- State -->
            <v-col cols="12" >
              <v-select
                  outlined
                v-model="form.state"
                :label="$t('common.material-forms.labels.state')"
                :items="$t('constants.materialFormsStates.list')"
              />
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
            class="mx-3"
            :is-loading-btn-save="isLoadingBtn.btnSave"
            @submit="customSubmit"
            @cancel="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import formRules from "@/mixins/formRules";

export default {
  name: "MaterialFormDialog",

  mixins: [dialogMixin, formRules],

  components: {
    SelectProjectImpact: () => import("@/components/Common/Inputs/SelectProjectImpact"),
    SelectSupplier: () => import("@/components/Common/Inputs/SelectSupplier"),
    SelectComponent: () => import("@/components/Common/Inputs/SelectComponent"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
    component: { type: Object, default: () => null },
    supplier: { type: Object, default: () => null },
    projectImpact: { type: Object, default: () => null }
  },

  data() {
    return {
      isLoadingBtn: {btnSave: false},

      defaultForm: {
        type: "TEXTILE",
        state: "TODO",
        component: this.component,
        supplier: this.component ? this.component.supplier : this.supplier,
        projectImpact: this.projectImpact
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;
        this.shortcutFormObjects(["component", "supplier", "projectImpact"])
        if (this.item)
          this.submitEdit();
        else
          this.submitAdd();
      }
    },

    submitAdd() {
      this.$http.post(`/material-forms`, this.form, {
        headers: {Authorization: "Bearer " + this.$session.get('jwt')}
      })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('common.material-forms.add.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
    },


    submitEdit() {

      this.$http
          .patch(`/material-forms/${this.item.id}`, this.form, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('common.material-forms.edit.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
    },

    onInputComponent(val) {
      this.form.component = val;
      if (val && this.form.supplier == null) {
        this.form.supplier = val.supplier;
      }
    },
    onInputSupplier(val) {
      this.form.supplier = val;
    },
    onInputProjectImpact(val) {
      this.form.projectImpact = val;
    }
  }
}
</script>

<style scoped>

</style>
